import * as React from 'react';

interface Props {
  src: string;
  fallback: string;
  width?: number;
  height?: number;
}

function canUseNativeEmoji() {
  return ['iphone', 'mac', 'ipod'].some((supportedDevice) => {
    return navigator.platform.toLowerCase().includes(supportedDevice);
  });
}

export const Emoji: React.FC<Props> = ({
  src,
  fallback,
  width = 18,
  height = 18,
}) => {
  const [url, setUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (canUseNativeEmoji()) return;

    const image = new Image(width, height);
    image.loading = 'lazy';
    image.src = src;

    image.onload = () => {
      setUrl(src);
    };
  }, [src, width, height]);

  if (url) {
    return <img src={url} alt={fallback} />;
  }

  return (
    <span className="flex items-center leading-[1px]" style={{ width, height }}>
      {fallback}
    </span>
  );
};
