import * as React from 'react';
import { SlackLogo } from '~/components/SlackLogo';

export const Logo: React.FunctionComponent = () => (
  <span
    className="text-black font-bold tracking-tight antialiased"
    title="Temp Channel"
  >
    <span className="text-rose-600">/tmp</span>channel
  </span>
);

export const ForSlack: React.FC = () => (
  <span className="relative text-sm border-l border-l-gray-300 text-gray-500 ml-2 pl-1 pt-1">
    {' '}
    <span className="pr-1">for</span>
    <span className="absolute top-[7px] left-[30px]">
      <SlackLogo variant="gray" style={{ width: 12, height: 12 }} />
    </span>
    <span className="pl-4">Slack</span>
  </span>
);
