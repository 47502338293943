import * as React from 'react';

const variants = {
  fullColor: [
    'fill-[#e01e5a] group-hover:fill-white',
    'fill-[#36c5f0] group-hover:fill-white',
    'fill-[#2eb67d] group-hover:fill-white',
    'fill-[#ecb22e] group-hover:fill-white',
  ],
  white: ['fill-white', 'fill-white', 'fill-white', 'fill-white'],
  gray: ['fill-gray-400', 'fill-gray-400', 'fill-gray-400', 'fill-gray-400'],
} as const;

type Props = {
  variant?: keyof typeof variants;
} & React.ComponentProps<'svg'>;

export const SlackLogo: React.FunctionComponent<Props> = ({
  variant = 'fullColor',
  ...props
}) => {
  const pathStyles = variants[variant];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.8 122.8"
      style={{
        height: 20,
        width: 20,
      }}
      {...props}
    >
      <path
        d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
        className={pathStyles[0]}
      ></path>
      <path
        d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
        className={pathStyles[1]}
      ></path>
      <path
        d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
        className={pathStyles[2]}
      ></path>
      <path
        d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
        className={pathStyles[3]}
      ></path>
    </svg>
  );
};
