import * as React from 'react';

function getCookie(name: string): string | null {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
}

export function useIsLoggedIn() {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    const isLoggedInCookie = getCookie('isLoggedIn');
    const newValue = Number(isLoggedInCookie) === 1;

    setIsLoggedIn(newValue);
  }, []);

  return isLoggedIn;
}
