import * as React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Head from 'next/head';
import cn from 'classnames';
import { Popover } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import { LoginWithSlackLink } from '~/components/LoginWithSlackLink';
import { Logo, ForSlack } from '~/components/Logo';
import { Emoji } from '~/components/Emoji';

import { useIsLoggedIn } from '~/lib/auth/hooks';

interface NavLinkProps {
  isActive?: boolean;
}

const NavLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentProps<'a'> & NavLinkProps
>(function NavLink({ isActive, className, ...props }, ref) {
  return (
    <a
      ref={ref}
      {...props}
      className={cn('px-3 py-2', { 'text-rose-600': isActive }, className)}
    />
  );
});

interface HeaderProps {
  workspace?: { id: string; name: string } | null;
}

const Header: React.FC<HeaderProps> = ({ workspace }) => {
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();

  const navState = (() => {
    switch (true) {
      case Boolean(workspace):
        return 'workspace';

      case isLoggedIn:
        return 'logged_in';

      default:
        return 'guest';
    }
  })();

  return (
    <header className="bg-white p-2 border-b-2">
      <div className="mx-auto max-w-5xl flex justify-between items-center">
        <h1>
          <Link href="/">
            <a className="text-lg sm:text-2xl p-2 block">
              <Logo />
              <ForSlack />
            </a>
          </Link>
        </h1>

        <Popover className="flex">
          <nav className="flex items-center">
            <Popover.Button className="md:hidden bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-400">
              <span className="sr-only">Open main menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>

            <div className="hidden md:flex md:items-center">
              <Link href="/support" passHref>
                <NavLink>Support</NavLink>
              </Link>
              {navState === 'workspace' && workspace ? (
                <>
                  <Link href="/manage" passHref>
                    <NavLink isActive={router.pathname.includes('/manage')}>
                      {workspace.name}
                    </NavLink>
                  </Link>
                  <Link href="/api/logout" passHref>
                    <NavLink>Sign out</NavLink>
                  </Link>
                </>
              ) : null}

              {navState === 'logged_in' ? (
                <Link href="/manage" passHref>
                  <NavLink isActive={router.pathname.includes('/manage')}>
                    Manage
                  </NavLink>
                </Link>
              ) : null}

              {navState === 'guest' ? (
                <LoginWithSlackLink passHref>
                  <NavLink>Sign in</NavLink>
                </LoginWithSlackLink>
              ) : null}
            </div>

            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="flex justify-between">
                  <div className="flex-grow">
                    <Link href="/support" passHref>
                      <NavLink className="block">Support</NavLink>
                    </Link>

                    {navState === 'workspace' && workspace ? (
                      <>
                        <div>
                          <Link href="/manage" passHref>
                            <NavLink
                              isActive={router.pathname.includes('/manage')}
                              className="block"
                            >
                              {workspace.name}
                            </NavLink>
                          </Link>
                        </div>
                        <div>
                          <Link href="/api/logout" passHref>
                            <NavLink className="block">Sign out</NavLink>
                          </Link>
                        </div>
                      </>
                    ) : null}

                    {navState === 'logged_in' ? (
                      <div>
                        <Link href="/manage" passHref>
                          <NavLink
                            isActive={router.pathname.includes('/manage')}
                            className="block"
                          >
                            Manage
                          </NavLink>
                        </Link>
                      </div>
                    ) : null}

                    {navState === 'guest' ? (
                      <div>
                        <LoginWithSlackLink passHref>
                          <NavLink className="block">Sign in</NavLink>
                        </LoginWithSlackLink>
                      </div>
                    ) : null}
                  </div>

                  <div>
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close main menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </nav>
        </Popover>
      </div>
    </header>
  );
};

const Footer: React.FC = () => (
  <div className="w-full mx-auto max-w-5xl p-5">
    <footer className="flex flex-col md:flex-row-reverse justify-between items-center">
      <nav className="flex flex-wrap justify-center">
        {(
          [
            ['Support', '/support'],
            ['Contact', 'mailto:hi@tmpchannel.com'],
            ['Terms', '/terms'],
            ['Privacy Policy', '/privacy'],
            [
              'Slack App Directory',
              'https://slack.com/apps/A03A01J7PG8',
              { target: '_blank', rel: 'noopener noreferrer' },
            ],
          ] as const
        ).map(([content, href, options]) => (
          <Link key={content} href={href}>
            <a {...(options ?? {})} className="px-2 py-1">
              {content}
            </a>
          </Link>
        ))}
      </nav>

      <section className="text-xl">
        <Link href="/">
          <a className="p-2 block">
            <Logo />
            <ForSlack />
          </a>
        </Link>
      </section>
    </footer>
  </div>
);

const DealBanner = () => (
  <div className="flex items-center justify-center text-sm bg-yellow-100 text-yellow-800 p-1">
    <Emoji src="/emojis/rocket.png" fallback="🚀" />{' '}
    <span className="ml-2">
      <strong>Limited time deal</strong> &ndash; Save up to 80% when signing up!
    </span>
  </div>
);

interface Props {
  workspace?: { id: string; name: string } | null;
  children: React.ReactNode;
  className?: string;
}

const meta = {
  title: 'TmpChannel: Slack app for quick communication via temporary channels',
  description: 'The Slack app for quick communication via temporary channels',
  keywords:
    'temporary channel,quick channel,slack app,productivity,project management',
  image: `${process.env.NEXT_PUBLIC_APP_URL ?? ''}/social/site.png?20220706`,
};

export const Layout: React.FC<Props> = ({ workspace, className, children }) => (
  <>
    <Head>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />

      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={meta.image} />
      <meta property="og:image:width" content="1024" />
      <meta property="og:image:height" content="512" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={meta.image} />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />

      <link rel="apple-touch-icon" sizes="57x57" href="/favicon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/favicon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/favicon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/favicon-76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/favicon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/favicon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/favicon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/favicon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon-180x180.png"
      />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/favicon-192x192.png"
      />
      <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/favicon-144x144.png" />
      <meta name="msapplication-config" content="/browserconfig.xml" />

      <meta name="slack-app-id" content="A03A01J7PG8" />
    </Head>

    <div className="flex flex-grow flex-col-reverse">
      <div className="flex flex-col flex-grow">
        <Header workspace={workspace} />

        <div className={cn('flex-grow', className)}>{children}</div>

        <Footer />
      </div>

      <DealBanner />
    </div>
  </>
);
